<template>
    <div class="container pt-5">

      <h2 class="mb-5 text-center font-600 color-azul-claro-text">
        {{ i18n.frase_reserva }}
      </h2>

      <iframe
          id="restaurante-espinssethotels"
          title="Reservas"
          :src="'https://www.covermanager.com/reserve/module_restaurant/restaurante-espinssethotels/' + i18n.motor_lang"
          frameborder="0"
          style="height: 100vh"
          width="100%"></iframe>

    </div>
</template>

<script>

    // @ is an alias to /src

    export default {
        name: 'ReservasView',

        components: {},
    }

</script>
