<template>
    <div class="row">
        <div class="col-lg-6 mb-3">
            <div>
                <img style="width: 100%" :src="src">
            </div>
        </div>
        <div class="col-lg-6 mb-3">
            <div class="vertical-align-parent position-relative" style="height: 100%; width: 100%">
                <div class="vertical-align-element text-center color-azul-text" style="padding: 50px 50px">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'FotoTextoIzquierda',

        props: ['src'],

        components: {}
    }
</script>
