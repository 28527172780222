<template>
  <div class="h-100">
    <WebLayout v-if="$route.meta.layout === 'web-layout' || $route.meta.layout === undefined">
      <router-view/>
    </WebLayout>
  </div>
  <FooterHome/>
</template>

<style lang="scss" rel="stylesheet/scss"></style>

<script>
import WebLayout from "@/views/layouts/WebLayout";
import FooterHome from "@/partials/FooterHome";

export default {
  components: {
    FooterHome,
    WebLayout,
  },

  data() {
    return {
      checkAuth: false
    };
  },

  mounted() {

    // Analytics
    fetch('https://api.sethotels.net/api/analytics/2b90f311-13fd-4b7a-a203-3fce56f392a2', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({url: window.location.href, referrer_url: document.referrer, title: document.title})
    });


  }
}
</script>
