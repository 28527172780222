<template>
    <Carousel class="d-none d-md-block" :items-to-show="4" :wrap-around="true">
        <Slide v-for="slide in images" :key="slide">
            <div
                    class="teal lighten-4 carousel__item me-2 ms-2 border-radius-15 backgrund-image-placement"
                    style="height: 250px; width: 100%"
                    v-bind:style="{'background-image': 'url(' + slide + ')'}"></div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>

    <Carousel class="d-block d-md-none" :items-to-show="1.3" :wrap-around="true">
        <Slide v-for="slide in images" :key="slide">
            <div
                    class="teal lighten-4 carousel__item me-2 ms-2 border-radius-15 backgrund-image-placement"
                    style="height: 250px; width: 100%"
                    v-bind:style="{'background-image': 'url(' + slide + ')'}"></div>
        </Slide>

        <template #addons>
            <Navigation />
            <Pagination />
        </template>
    </Carousel>
</template>

<script>
    // @ is an alias to /src
    import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'

    import 'vue3-carousel/dist/carousel.css'

    export default {
        name: 'PhotoCarousel',

        props: ['images'],

        components: {
            Carousel,
            Slide,
            Navigation,
            Pagination,
        },
    }
</script>

<style scoped>

    .carousel__icon{
        color: white;
    }

    .carousel__pagination-button:hover::after{
        background-color: #0D47A1 !important;
    }

    .carousel__pagination-button--active::after{
        background-color: #0D47A1 !important;
    }

</style>
