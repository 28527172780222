<template>

    <div>
        <Navbar />
    </div>

    <slot></slot>

</template>


<script>

    import Navbar from "@/partials/Navbar";

    export default {
        components: {
            Navbar
        },

        data() {
            return {
                checkAuth: false
            };
        },

        mounted() {}
    }
</script>
