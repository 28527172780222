<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="71mm" height="21mm" viewBox="0 0 201.25 59.52">
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path class="cls-1" d="M2.22,49.11h.56l6,9.44h2.73L5.19,48.91c3.07-.28,5-2.62,5-5.93,0-4.85-3.52-5.93-7.31-5.93H0v21.5H2.22Zm0-9.84h.66c2.68,0,5.09.34,5.09,3.88C8,46.49,5.43,47,2.91,47H2.22Z"/>
                <polygon class="cls-1" points="17.68 58.55 28.03 58.55 28.03 56.33 19.9 56.33 19.9 47.88 27.79 47.88 27.79 45.66 19.9 45.66 19.9 39.27 28.03 39.27 28.03 37.05 17.68 37.05 17.68 58.55"/>
                <path class="cls-1" d="M46,40a5.69,5.69,0,0,0-5.16-3.34,5.42,5.42,0,0,0-5.59,5.48c0,3.09,2,4.37,4.34,5.51l1.22.57c1.85.91,3.68,1.77,3.68,4.28a4.16,4.16,0,0,1-4.1,4.19,4.25,4.25,0,0,1-4-4l-2.17.66c.64,3.34,3,5.56,6.22,5.56a6.4,6.4,0,0,0,6.27-6.64c0-3.42-2.2-4.82-4.79-6.05l-1.32-.63c-1.35-.65-3.13-1.51-3.13-3.36a3.28,3.28,0,0,1,3.31-3.34,3.65,3.65,0,0,1,3.44,2.34Z"/>
                <polygon class="cls-1" points="58.56 39.27 63.43 39.27 63.43 37.05 51.52 37.05 51.52 39.27 56.34 39.27 56.34 58.55 58.56 58.55 58.56 39.27"/>
                <path class="cls-1" d="M77.56,50.22H70.81l3.41-8.61Zm.87,2.23,2.36,6.1h2.46L74.28,36,65.07,58.55H67.5l2.41-6.1Z"/>
                <path class="cls-1" d="M90.92,37.05H88.69V50.51c0,2.45.22,4.42,2,6.22a7.18,7.18,0,0,0,5.15,2.19,7.09,7.09,0,0,0,5.38-2.45c1.59-1.77,1.75-3.62,1.75-6V37.05h-2.23v12.8c0,1.68.08,3.57-1,4.94a5.13,5.13,0,0,1-3.93,1.91,5,5,0,0,1-4-2.05c-.93-1.37-.88-3.2-.88-4.8Z"/>
                <path class="cls-1" d="M112.43,49.11H113l6,9.44h2.73l-6.36-9.64c3.07-.28,5-2.62,5-5.93,0-4.85-3.52-5.93-7.31-5.93h-2.88v21.5h2.22Zm0-9.84h.66c2.67,0,5.08.34,5.08,3.88,0,3.34-2.54,3.85-5,3.85h-.69Z"/>
                <path class="cls-1" d="M138.53,50.22h-6.75l3.41-8.61Zm.87,2.23,2.36,6.1h2.46l-9-22.53L126,58.55h2.44l2.41-6.1Z"/>
                <polygon class="cls-1" points="149.85 58.55 152.07 58.55 152.07 41.98 167.63 59.52 167.63 37.05 165.41 37.05 165.41 53.71 149.85 36.13 149.85 58.55"/>
                <polygon class="cls-1" points="180.42 39.27 185.29 39.27 185.29 37.05 173.38 37.05 173.38 39.27 178.2 39.27 178.2 58.55 180.42 58.55 180.42 39.27"/>
                <polygon class="cls-1" points="190.9 58.55 201.25 58.55 201.25 56.33 193.12 56.33 193.12 47.88 201.01 47.88 201.01 45.66 193.12 45.66 193.12 39.27 201.25 39.27 201.25 37.05 190.9 37.05 190.9 58.55"/>
                <path class="cls-1" d="M70.09,14.2H63.34l3.41-8.61ZM71,16.43l2.35,6.1h2.47L66.81,0,57.6,22.53H60l2.41-6.1Z"/>
                <path class="cls-1" d="M96.5,2.77a9.17,9.17,0,0,0-6-2.11c-5.71,0-10.4,5-10.4,11.21s4.77,11,10.4,11a9.74,9.74,0,0,0,6-2.11v-3a8.46,8.46,0,0,1-6,2.85c-4.47,0-8.09-4.17-8.09-8.9s3.6-8.9,8-8.9A8.25,8.25,0,0,1,96.5,5.73Z"/>
                <path class="cls-1" d="M136.79,3.25h.87c2.73,0,4.64.34,4.64,3.6s-2.15,3.65-4.66,3.65h-.85Zm-2.22,19.28h4.63c3.94,0,7.14-1.71,7.14-6a5.42,5.42,0,0,0-3.78-5.5,4.91,4.91,0,0,0,2-4.19c0-4.4-3.05-5.82-6.67-5.82h-3.28Zm2.22-10h1.93c2.44,0,5.4.48,5.4,3.82s-2.62,3.94-5.11,3.94h-2.22Z"/>
                <path class="cls-1" d="M26,3.11h.87c2.72,0,4.63.35,4.63,3.6s-2.14,3.65-4.66,3.65H26ZM23.81,22.4h4.62c4,0,7.15-1.71,7.15-6a5.43,5.43,0,0,0-3.79-5.51,4.87,4.87,0,0,0,2-4.19c0-4.39-3-5.82-6.67-5.82H23.81Zm2.22-10H28c2.43,0,5.4.49,5.4,3.83s-2.62,3.93-5.11,3.93H26Z"/>
                <path class="cls-1" d="M163.57,14.2h-6.75l3.41-8.61Zm.88,2.23,2.35,6.1h2.46L160.29,0l-9.21,22.53h2.43l2.41-6.1Z"/>
                <path class="cls-1" d="M177,13.09h.55l6,9.44h2.73L180,12.89c3.07-.28,5-2.62,5-5.93,0-4.85-3.52-5.94-7.3-5.94h-2.89V22.53H177Zm0-9.84h.66c2.67,0,5.08.34,5.08,3.88,0,3.34-2.54,3.85-5.06,3.85H177Z"/>
                <path class="cls-1" d="M116.13,1h1.56a.27.27,0,0,1,.3.31V22a.27.27,0,0,1-.3.3h-1.56a.27.27,0,0,1-.3-.3V12.68a.11.11,0,0,0-.12-.12H106c-.09,0-.13,0-.13.12V22a.27.27,0,0,1-.3.3H104c-.21,0-.31-.1-.31-.3V1.33A.28.28,0,0,1,104,1h1.55a.27.27,0,0,1,.3.31v9.22c0,.08,0,.12.13.12h9.71a.11.11,0,0,0,.12-.12V1.33A.27.27,0,0,1,116.13,1Z"/>
                <polygon class="cls-1" points="41.92 22.43 52.27 22.43 52.27 20.2 44.14 20.2 44.14 11.76 52.02 11.76 52.02 9.54 44.14 9.54 44.14 3.15 52.27 3.15 52.27 0.93 41.92 0.93 41.92 22.43"/>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'TextoEsPins',
    }
</script>

<style scoped>
    .cls-1 {
        fill: #b4bfe3;
    }
</style>
