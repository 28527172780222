<template>

    <div class="white p-3"
         id="sideNav"
         style="position: fixed; height: 100vh; width: 300px; border-radius: 0px 20px 20px 0px; z-index: 999">
        <div class="clearfi mb-4">
            <i @click="toggleNavigation" class="fa-solid fa-xmark float-end font-30 pe-1 blue-grey-text text-darken-2 cursor-pointer"></i>
        </div>

        <div class="clearfix">
            <div style="width: fit-content; margin: auto" class="mb-4">
                <router-link to="/" class="text-decoration-none color-text font-size-30 font-400 text-uppercase text-gradient">
                    <EsPinsLogo style=" height: 70px"/>
                </router-link>
            </div>
            <div class="mb-2">
                <a href="/" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                    {{i18n.navbar.home}}
                </a>
            </div>
            <div class="mb-2">
                <a href="#menu" @click="toggleNavigation" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                    {{i18n.navbar.carta}}
                </a>
            </div>
            <div class="mb-2">
                <a href="#geolocal" @click="toggleNavigation" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                    {{i18n.navbar.donde_estamos}}
                </a>
            </div>
            <div class="mb-2">
                <a href="#menu" @click="toggleNavigation" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                    {{i18n.navbar.reservar}}
                </a>
            </div>
        </div>
    </div>

    <div class="teal-text color-text p-3 font-size-16 font-500">
        <div class="container clearfix" style="line-height: 45px">
            <router-link to="/" class="float-start text-decoration-none color-text font-size-30 font-400 text-uppercase text-gradient">
                <EsPinsLogo style="height: 50px" class="me-3"/>
                <TextoEsPins style="height: 32px; width: fit-content" />
            </router-link>

            <div class="float-end color-azul-claro-text" style="line-height: 50px">
                <!-- Menu Desktop -->
                <div class="d-none d-md-block">
                    <a href="/" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                        {{i18n.navbar.home}}
                    </a>

                    <a href="#menu" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                        {{i18n.navbar.carta}}
                    </a>

                    <a href="#geolocal" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                        {{i18n.navbar.donde_estamos}}
                    </a>

                    <a href="#menu" class="color-azul-claro-text me-4 text-decoration-none text-uppercase">
                        {{i18n.navbar.reservar}}
                    </a>

                    <span class="me-3">|</span>

                    <a href="/" @click="setLocale('es')" class="color-azul-claro-text me-1 text-decoration-none">
                        ES
                    </a>

                    <a href="/" @click="setLocale('en')" class="color-azul-claro-text me-1 text-decoration-none">
                        EN
                    </a>

                    <a href="/" @click="setLocale('ca')" class="color-azul-claro-text me-3 text-decoration-none">
                        CA
                    </a>
                </div>

                <div class="d-block d-md-none">
                    <IconMenu2 @click="toggleNavigation" class="cursor-pointer" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import EsPinsLogo from "@/svg/EsPinsLogo";
    import {IconMenu2} from '@tabler/icons-vue';
    import {toggleNav} from "@/functions";
    import TextoEsPins from "@/svg/TextoEsPins";

    export default {

        props: ['auth'],

        name: 'NavBar',

        components: {
            TextoEsPins,
            EsPinsLogo,
            IconMenu2,
        },

        methods: {
            setLocale(locale){
                localStorage.locale = locale;
            },
            toggleNavigation(){
                toggleNav();
            }
        },

        mounted() {

        }
    }
</script>
