<template>
    <footer class="color-azul-claro white-text">
        <div class="p-3">
            <div class="container pt-3 pb-3">
                <div class="row">
                    <div class="col-lg-6 ps-3" style="border-left: 2px solid white">
                        <div class="mb-2">
                            <a href="https://sethotels.com/aviso-legal" class="white-text text-decoration-none">
                                {{i18n.buttons.aviso_legal}}
                            </a>
                        </div>

                        <div class="mb-2">
                            <a href="https://sethotels.com/cookies" class="white-text text-decoration-none">
                                {{i18n.buttons.politica_cookies}}
                            </a>
                        </div>

                        <div class="mb-2">
                            <a href="https://sethotels.com/aviso-legal" class="white-text text-decoration-none">
                                {{i18n.buttons.privacidad}}
                            </a>
                        </div>

                        <a href="https://sethotels.com/en/trabaja-con-nosotros" class="white-text text-decoration-none">
                            {{i18n.buttons.trabaja}}
                        </a>
                    </div>

                    <div class="col-lg-6 position-relative" style="min-height: 50px">
                        <div class="position-absolute" style="bottom: 0px; right: 0px">
                            <a href="https://www.instagram.com/restaurant_espins/" target="_blank">
                                <InstagramLogo style="height: 40px"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="color-azul p-3 text-center white-text" style="background-color: #6788d0">
            {{i18n.footer_text}}
            <a class="white-text text-decoration-none font-600"
               target="_blank"
               href="https://sethotels.com">
                Set Hotels
            </a>
        </div>
    </footer>

</template>

<script>
    import InstagramLogo from "@/svg/InstagramLogo";

    export default {
        props: ['auth'],

        name: 'FooterHome',

        components: {
            InstagramLogo
        }
    }
</script>
